import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Clerk } from "@formclerk/react"

const ContactForm = ({ children, id, submitValue = "Kontakta mig" }) => {
  const { clerkBaseID } = useStaticQuery(graphql`
    query ClerkIDQuery {
      site {
        siteMetadata {
          clerkBaseID
        }
      }
    }
  `).site.siteMetadata

  const clerkID = `${clerkBaseID}/${id}`

  return (
    <Clerk
      id={id}
      className="form contact-form"
      clerkID={clerkID}
      Initializing={() => <p>Laddar...</p>}
      Processing={() => <p>Skickar ditt meddelande, vänligen vänta...</p>}
      Success={() => (
        <p>Tack för att du kontaktat oss, vi hör av oss så snart vi kan.</p>
      )}
      Failure={() => (
        <p>
          Ajdå, någonting verkar ha gått snett. Förhoppningsvis har vi ändå
          kunnat ta vara på ditt meddelande, men för säkerhets skull - kontakta
          oss även på annat vis.
        </p>
      )}
    >
      {children}
      <label className="form__label">
        Telefonnummer och/eller e-postadress *
        <input
          name="kontaktuppgift"
          type="text"
          className="form__input"
          required={true}
        />
      </label>
      <label className="form__label">
        Namn *
        <input
          name="Namn"
          type="text"
          className="form__input"
          required={true}
        />
      </label>
      <label className="form__label">
        Meddelande *
        <textarea
          name="Meddelande"
          className="form__input"
          required={true}
          rows="6"
          cols="30"
        />
        <p className="form__description">
          Skicka inte några känsliga uppgifter via formuläret. Informationen
          skickas via e-post, vilket är ungefär lika privat som att skicka
          vykort.
        </p>
      </label>
      <input
        type="submit"
        value={submitValue}
        className="form__submit button button--filled button--purple"
      />
    </Clerk>
  )
}

export default ContactForm
