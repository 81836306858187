import { graphql, useStaticQuery } from "gatsby"

const ContactCategories = () => {
  const { allGoogleSheetKontaktRow } = useStaticQuery(graphql`
    {
      allGoogleSheetKontaktRow {
        edges {
          node {
            kontaktid
            kategori
            namn
            beskrivning
            telefon
            mobil
          }
        }
      }
    }
  `)

  let categories = {}

  allGoogleSheetKontaktRow.edges.forEach((edge, index) => {
    const contactId = edge.node.kontaktid
    const category = edge.node.kategori
    const contact = {
      contactId: contactId,
      name: edge.node.namn,
      description: edge.node.beskrivning,
      phone: edge.node.telefon,
      mobile: edge.node.mobil,
    }

    if (categories.hasOwnProperty(category)) {
      categories[category].push(contact)
      return
    }

    categories[category] = [contact]
  })

  return categories
}

export default ContactCategories
