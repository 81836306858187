import React from "react"
import closeIcon from "../images/fontawesome/window-close-solid.svg"

const CloseButton = ({ onClick }) => (
  <button
    className="button close-button"
    onClick={onClick}
    aria-label="Stäng formulär"
  >
    <img
      src={closeIcon}
      alt="Stäng formulär"
      title="Stäng formulär"
      className="social-media-icon"
      width="35px"
      height="35px"
    />
  </button>
)

export default CloseButton
