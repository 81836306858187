import React, { useState } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"

import CloseButton from "../components/closeButton"
import ContactForm from "../components/contactForm"

const Contact = ({ children, description, contactId, mobile, name, phone }) => {
  const [formActive, setFormActive] = useState(false)

  const { employeeImages } = useStaticQuery(graphql`
    {
      employeeImages: allFile(
        filter: { relativeDirectory: { eq: "employees" } }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              quality: 80
              layout: FIXED
              width: 195
              height: 258
              transformOptions: { cropFocus: NORTH }
            )
          }
        }
      }
    }
  `)

  const validFormId = contactId =>
    typeof contactId === "string" && contactId.length > 0

  const image = getImage(
    employeeImages.nodes.find(node => node.name === contactId)
  )

  return (
    <div className="contact">
      {name && <h3 className="contact__heading">{name}</h3>}
      {image && <GatsbyImage image={image} alt={name} />}
      {description && (
        <p>
          {description}
          <br />
        </p>
      )}
      <address className="contact__phone">
        {phone && (
          <>
            <a href={`tel:+46${phone}`}>{phone}</a>
            <br />
          </>
        )}
        {mobile && (
          <>
            <a href={`tel:+46${mobile}`}>{mobile}</a>
            <br />
          </>
        )}
      </address>
      {validFormId(contactId) && !formActive && (
        <button
          className="button button--purple button--outlined contact__email-button"
          onClick={() => setFormActive(true)}
        >
          Skicka e-post till {name}
        </button>
      )}
      {formActive && (
        <div className="contact__form-wrapper">
          <ContactForm id={contactId} submitValue={`Kontakta ${name}`}>
            <h4 className="contact__sub-heading">Kontakta {name}</h4>
          </ContactForm>
          <CloseButton onClick={() => setFormActive(false)} />
        </div>
      )}
    </div>
  )
}

export default Contact
